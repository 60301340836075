/* Component Dependencies */
var recentlyViewedPropertyTemplate = require('templates/recentlyViewedProperty.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'recentlyViewedProperty',
  template: {
    'recentlyViewedProperty': recentlyViewedPropertyTemplate
  },
  bindEvents: function() {
    var recentViewedData = recentlyViewedProperty;
    var _self = this;
    try {
      if(!recentViewedData || !recentViewedData.config || !recentViewedData.recentlyViewedProperty){
        return;
      }
      var cachedData = JSON.parse(window.localStorage.getItem('miRecentlyViewedProperties'));
      var currentTime = new Date().toISOString();
      if(!cachedData){
        recentViewedData.recentlyViewedProperty.createdAt = currentTime;
        _self.pushToCache({config: recentViewedData.config, recentlyViewedProperties: [recentViewedData.recentlyViewedProperty]});
        return;
      }
     
      var filterData = cachedData.recentlyViewedProperties.filter(function(item){
      var daysDifference = _self.daysDateDifference(currentTime, item.createdAt);
        return ((item.marshaCode.toLowerCase() !== recentViewedData.recentlyViewedProperty.marshaCode.toLowerCase()) && (daysDifference <= recentViewedData.config.maxCachedDaysLimit));
      });
      recentViewedData.recentlyViewedProperty.createdAt = currentTime;
      filterData.splice(0,0, recentViewedData.recentlyViewedProperty);
      _self.pushToCache({config: recentViewedData.config, recentlyViewedProperties: filterData.slice(0, recentViewedData.config.maxCachedPropertiesLimit)});
    }
    catch(err) {
      console.error(err);
    }
  },
  pushToCache: function _pushToCache(recentlyViewedProperties) {
    window.localStorage.setItem('miRecentlyViewedProperties', JSON.stringify(recentlyViewedProperties));
  },

  daysDateDifference : function _daysDateDifference(newDate, oldDate){
    var timeDiff = Math.abs(new Date(newDate).getTime() - new Date(oldDate).getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }
});
